<template>
  <Header title="Pracoviská" />
  <div id="donation-places" class="container">
    <div class="row">
      <div class="col-12">
        <ul class="item-list" v-if="donationPlaces?.length > 0">
          <li v-for="(item, index) in donationPlaces" v-bind:key="index" class="item-list-item">
            <span class="line-text">
              <span class="line-primary">{{ item.Nazov }}</span>
              <span class="line-secondary">{{ item.Adresa }}</span>
            </span>
            <a class="info-button"><i class="bi bi-info-circle-fill"/></a>
          </li>
        </ul>
        <span v-if="userHistory === null || userHistory?.length === 0" class="no-data">Nemáte žiadny odber</span>
      </div>
    </div>
    <pre class="d-none">{{donationPlaces}}</pre>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import Header from '@/components/Header'

export default {
  name: 'DonationPlaces',
  components: {
    Header
  },
  computed: mapState([
    'donationPlaces'
  ]),
  beforeMount () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.donationPlaces === null && this.$store.dispatch('fetchDonationPlaces')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../functions.scss";

#donation-places {

}
</style>
